export const transactionManagement = {
  transactionManagement: {
    columns: {
      id: "Transaction ID",
      username: "Username",
      amount: "Amount",
      currency: "Currency",
      type: "User Action",
      userAction: {
        PLAYER_FOLD: "Player folds and gives up their cards.",
        PLAYER_CALL: "Player calls and matches the previous bet.",
        PLAYER_SIT: "Player sits down at the table.",
        PLAYER_CHECK: "Player checks and passes their turn.",
        PLAYER_BET: "Player places a new bet.",
      },
    },
    filters: {
      lobbyId: "Lobby",
      lobbyId_placeholder: "Enter Lobby",
      lobbyId_required: "Please enter Lobby",
      gameType: "Game Type",
      gameType_placeholder: "Enter Game Type",
      gameType_required: "Please enter Game Type",
      tableName: "Table Name",
      tableName_placeholder: "Enter Table Name",
      tableName_required: "Please enter Table Name",
      gameId: "Round ID",
      gameId_placeholder: "Enter Round ID",
      gameId_required: "Please enter Round ID",
      tableName_disabled: "Please select a Lobby and Game Type first",
    },
  },
};
