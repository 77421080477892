var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import createStore from "@/hooks/createStore";
var initialAppState = {
    tenant: "",
};
var appReducer = function (state, action) {
    switch (action.type) {
        case "SET_TENANT":
            return __assign(__assign({}, state), { tenant: action.payload });
        default:
            return state;
    }
};
var _a = createStore(appReducer, initialAppState), AppProvider = _a.Provider, useAppStore = _a.useStore;
export { AppProvider, useAppStore };
