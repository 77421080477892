var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserControllerApiFactory } from "@/apis/user-management";
import InfinityTable from "@/components/Infinitytable";
import { LocalStorageKeys } from "@/constants/localStorage";
import { useApi } from "@/hooks/useApi";
import { Card, Divider, Modal, message } from "@atom/ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { columns } from "./UserManagement.columns";
import UserManagementFilterSpace from "./UserManagement.filters";
var DEFAULT_PAGE_SIZE = 50;
var UserManagement = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState({
        tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
        limit: DEFAULT_PAGE_SIZE,
        requestType: "all",
    }), reqParams = _a[0], setReqParams = _a[1];
    var _b = useState([]), tableData = _b[0], setTableData = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    // Dinamik API çağrısı yapan fonksiyon
    var fetchTableData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 9, 10, 11]);
                    setLoading(true);
                    response = void 0;
                    _a = reqParams.requestType;
                    switch (_a) {
                        case "all": return [3 /*break*/, 1];
                        case "userId": return [3 /*break*/, 3];
                        case "userName": return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 7];
                case 1: return [4 /*yield*/, UserControllerApiFactory().backofficeusersGet(reqParams)];
                case 2:
                    response = (_b.sent());
                    setTableData(response.data.items);
                    return [3 /*break*/, 8];
                case 3: return [4 /*yield*/, UserControllerApiFactory().backofficeusersIdGet({
                        id: reqParams.userId,
                    })];
                case 4:
                    response = (_b.sent());
                    setTableData([response.data]);
                    return [3 /*break*/, 8];
                case 5: return [4 /*yield*/, UserControllerApiFactory().backofficeusersByTenantUsernameGet({
                        username: reqParams.userName,
                        tenant: reqParams.tenant,
                    })];
                case 6:
                    response = (_b.sent());
                    setTableData([response.data]);
                    return [3 /*break*/, 8];
                case 7:
                    message.error("Invalid request type");
                    return [2 /*return*/];
                case 8: return [3 /*break*/, 11];
                case 9:
                    error_1 = _b.sent();
                    message.error("Failed to fetch table data");
                    return [3 /*break*/, 11];
                case 10:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var _d = useApi({
        asyncFunction: UserControllerApiFactory().backofficeusersByTenantUsernameDelete,
        successCallback: function (response) {
            message.success(t("root.delete_success"));
            fetchTableData();
        },
        errorCallback: function (error) {
            message.error(t("root.delete_error"));
        },
    }), callDeleteUserManagement = _d.call, deleteUserManagementLoading = _d.loading;
    var handleDelete = function (username) {
        Modal.confirm({
            title: t("root.delete_confirmation_title"),
            content: t("root.delete_confirmation_message"),
            okText: t("root.confirm"),
            okType: "danger",
            cancelText: t("root.cancel"),
            onOk: function () {
                callDeleteUserManagement({
                    tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
                    username: username,
                });
            },
        });
    };
    var handleAdd = function () {
        navigate("/user-management/add");
    };
    var handleEdit = function (key, tenant) {
        navigate("/user-management/".concat(key, "?tenant=").concat(tenant));
    };
    var handleSearch = function (values) {
        setReqParams(__assign(__assign(__assign({}, reqParams), values), { requestType: values.userId ? "userId" : values.userName ? "userName" : "all" }));
    };
    useEffect(function () {
        fetchTableData();
    }, [reqParams]);
    useEffect(function () {
        if (location.state && location.state.searchText) {
            setReqParams(__assign(__assign({}, reqParams), { userName: location.state.searchText, requestType: "userName" }));
        }
    }, [location.state]);
    return (_jsxs(Card, { style: { padding: "20px 0", height: "80vh" }, children: [_jsx(UserManagementFilterSpace, { onAddNewData: handleAdd, onSearch: handleSearch }), _jsx(Divider, {}), _jsx(InfinityTable, { columns: columns(handleEdit, handleDelete, t), dataSource: tableData, loading: loading || deleteUserManagementLoading })] }));
};
export default UserManagement;
