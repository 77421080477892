import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DefaultApiFactory } from "@/apis/transaction-management";
import InfinityTable from "@/components/Infinitytable";
import { LocalStorageKeys } from "@/constants/localStorage";
import { useApi } from "@/hooks/useApi";
import { Card } from "@atom/ui-kit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { columns } from "./TransactionManagement.columns";
import TransactionManagementFilterSpace from "./TransactionManagement.filters";
var DEFAULT_PAGE_SIZE = 50;
var TransactionManagement = function () {
    var _a;
    var t = useTranslation().t;
    var _b = useApi({
        asyncFunction: DefaultApiFactory().getTransactionsByGameId,
    }), callTransactionManagementList = _b.call, transactionManagementData = _b.data, transactionManagementLoading = _b.loading;
    var handleSearch = function (values) {
        callTransactionManagementList({ gameid: values.gameId, tenant: "wisegaming" });
    };
    var handleOpenDetail = function (record) {
        console.log(record);
    };
    useEffect(function () {
        callTransactionManagementList({ gameid: "2d1baddc-59a0-4f38-8db7-fc9d9ab65121", tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming" });
    }, []);
    return (_jsxs(Card, { style: { padding: "20px 0", height: ((_a = transactionManagementData === null || transactionManagementData === void 0 ? void 0 : transactionManagementData.data.length) !== null && _a !== void 0 ? _a : 0) > 0 ? "80vh" : "15vh" }, children: [_jsx(TransactionManagementFilterSpace, { onSearch: handleSearch }), transactionManagementData && _jsx(InfinityTable, { columns: columns(t, handleOpenDetail), dataSource: transactionManagementData.data, loading: transactionManagementLoading })] }));
};
export default TransactionManagement;
