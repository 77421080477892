import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useAuthInterceptor from "@/hooks/useAuthInterceptor";
import { AppLayout } from "@/layouts/AppLayout";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
import SignIn from "./sign-in";
import { GameManagement } from "./game-management";
import AddGameManagement from "./game-management/add";
import EditGameManagement from "./game-management/edit";
import LobbyManagement from "./lobby-management/LobbyManagement";
import AddLobbyManagement from "./lobby-management/add";
import EditLobbyManagement from "./lobby-management/edit";
import { TableManagement } from "./table-management";
import AddTableManagement from "./table-management/add";
import EditTableManagement from "./table-management/edit";
import TenantManagement from "./tenant-management/TenantManagement";
import AddTenantManagement from "./tenant-management/add";
import EditTenantManagement from "./tenant-management/edit";
import PlayerManagement from "./player-management/PlayerManagement";
import EditPlayerManagement from "./player-management/edit";
import UserManagement from "./user-management/UserManagement";
import AddUserManagement from "./user-management/add";
import EditUserManagement from "./user-management/edit";
import TransactionManagement from "./transaction-management";
import "../i18n/i18n";
function AppRouter() {
    useAuthInterceptor();
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/sign-in", element: _jsx(SignIn, {}) }), _jsxs(Route, { path: "*", element: _jsx(AppLayout, {}), children: [_jsx(Route, { index: true, element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "table-management", element: _jsx(TableManagement, {}) }), _jsx(Route, { path: "table-management/add", element: _jsx(AddTableManagement, {}) }), _jsx(Route, { path: "table-management/:id", element: _jsx(EditTableManagement, {}) }), _jsx(Route, { path: "game-management", element: _jsx(GameManagement, {}) }), _jsx(Route, { path: "game-management/add", element: _jsx(AddGameManagement, {}) }), _jsx(Route, { path: "game-management/:id", element: _jsx(EditGameManagement, {}) }), _jsx(Route, { path: "lobby-management", element: _jsx(LobbyManagement, {}) }), _jsx(Route, { path: "lobby-management/add", element: _jsx(AddLobbyManagement, {}) }), _jsx(Route, { path: "lobby-management/:id", element: _jsx(EditLobbyManagement, {}) }), _jsx(Route, { path: "tenant-management", element: _jsx(TenantManagement, {}) }), _jsx(Route, { path: "tenant-management/add", element: _jsx(AddTenantManagement, {}) }), _jsx(Route, { path: "tenant-management/:id", element: _jsx(EditTenantManagement, {}) }), _jsx(Route, { path: "user-management", element: _jsx(UserManagement, {}) }), _jsx(Route, { path: "user-management/add", element: _jsx(AddUserManagement, {}) }), _jsx(Route, { path: "user-management/:id", element: _jsx(EditUserManagement, {}) }), _jsx(Route, { path: "player-management", element: _jsx(PlayerManagement, {}) }), _jsx(Route, { path: "player-management/:id", element: _jsx(EditPlayerManagement, {}) }), _jsx(Route, { path: "transaction-management", element: _jsx(TransactionManagement, {}) })] })] }));
}
export default AppRouter;
