var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return props.isTransparent
        ? "\n        .ant-select {\n          background: transparent !important;\n          \n          .ant-select-selector{\n            border: 2px solid #fff;\n            background: transparent;\n            box-shadow: none;\n\n            .ant-select-selection-item{\n              color: #fff;\n              text-transform: uppercase;\n              }\n          }\n\n\n      }"
        : "";
});
var templateObject_1;
