import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LobbyControllerApiFactory } from "@/apis/lobby-management";
import InfinityTable from "@/components/Infinitytable";
import { LocalStorageKeys } from "@/constants/localStorage";
import { useApi } from "@/hooks/useApi";
import { Card, Modal, message } from "@atom/ui-kit";
import { Divider } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { columns } from "./LobbyManagement.columns";
import LobbyManagementFilterSpace from "./LobbyManagement.filters";
var DEFAULT_PAGE_SIZE = 50;
var LobbyManagement = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _a = useApi({
        asyncFunction: LobbyControllerApiFactory().lobbiesTenantGet,
    }), callLobbyManagementList = _a.call, lobbyManagementData = _a.data, lobbyManagementLoading = _a.loading;
    var _b = useApi({
        asyncFunction: LobbyControllerApiFactory().lobbiesTenantIdDelete,
        successCallback: function (response) {
            message.success(t("root.delete_success"));
            callLobbyManagementList();
        },
        errorCallback: function (error) {
            message.error(t("root.delete_error"));
        },
    }), callDeleteLobbyManagement = _b.call, deleteLobbyManagementLoading = _b.loading;
    var handleDelete = function (id) {
        Modal.confirm({
            title: t("root.delete_confirmation_title"),
            content: t("root.delete_confirmation_message"),
            okText: t("root.confirm"),
            okType: "danger",
            cancelText: t("root.cancel"),
            onOk: function () {
                callDeleteLobbyManagement({
                    id: id,
                });
            },
        });
    };
    var handleAdd = function () {
        navigate("/lobby-management/add");
    };
    var handleEdit = function (key, record) {
        navigate("/lobby-management/".concat(key), {
            state: { lobby: record },
        });
    };
    useEffect(function () {
        callLobbyManagementList({
            limit: DEFAULT_PAGE_SIZE,
            tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
        });
    }, []);
    return (_jsxs(Card, { style: { padding: "20px 0", height: "80vh" }, children: [_jsx(LobbyManagementFilterSpace, { onAddNewData: handleAdd, onSearch: function () {
                    return callLobbyManagementList({
                        limit: DEFAULT_PAGE_SIZE,
                        tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
                    });
                } }), _jsx(Divider, {}), _jsx(InfinityTable, { columns: columns(handleEdit, handleDelete, t), dataSource: lobbyManagementData === null || lobbyManagementData === void 0 ? void 0 : lobbyManagementData.data.items, loading: lobbyManagementLoading || deleteLobbyManagementLoading })] }));
};
export default LobbyManagement;
