export const gameManagement = {
  gameManagement: {
    edit: {
      title: "Edit Game",
      id: "ID",
      id_required: "Please enter ID",
      table_id: "Table ID",
      players: "Players",
      last_bet_amount: "Last Bet Amount",
      dealer_position: "Dealer Position",
      current_turn: "Current Turn",
      community_cards: "Community Cards",
      pot: "Pot",
      round: "Round",
    },
    add: {
      title: "Add Game",
      table_id: "Table ID",
      table_id_placeholder: "Enter Table ID",
      players: "Players",
      players_placeholder: "Enter Players",
      last_bet_amount: "Last Bet Amount",
      last_bet_amount_placeholder: "Enter Last Bet Amount",
      dealer_position: "Dealer Position",
      dealer_position_placeholder: "Enter Dealer Position",
      current_turn: "Current Turn",
      current_turn_placeholder: "Enter Current Turn",
      community_cards: "Community Cards",
      community_cards_placeholder: "Enter Community Cards",
      pot: "Pot",
      pot_placeholder: "Enter Pot",
      round: "Round",
      round_placeholder: "Enter Round",
      success: "GameManagement added successfully",
      error: "Error adding GameManagement",
    },
    columns: {
      tenant: "Tenant",
      id: "ID",
      table_id: "Table ID",
      lobby_id: "Lobby ID",
      name: "Name",
      type: "Type",
      phase: "Phase",
      currency: "Currency",
      category: "Category",
      limitType: "Limit Type",
      version: "Version",
      skincode: "Skin Code",
      organization: "Organization",
      players: "Players",
      deck: "Deck",
      community_cards: "Community Cards",
      lastBetAmount: "Last Bet Amount",
      pot: "Pot",
      serviceFeePercent: "Service Fee Percent",
      totalSeats: "Total Seats",
      currentPlayerIndex: "Current Player Index",
      currentTurn: "Current Turn",
      timeLimitMs: "Time Limit (ms)",
      minBuyIn: "Buy-In",
      maxBuyIn: "Maximum Buy-In",
      ante: "Ante",
      timeoutDefaultAction: "Timeout Default Action",
      smallBlindIndex: "Small Blind Index",
      bigBlindIndex: "Big Blind Index",
      dealerPosition: "Dealer Position",
      inProgress: "In Progress",
      isPasswordProtected: "Is Password Protected",
      isActive: "Is Active",
      blinds: "Blinds",
      createdAt: "Created At",
      updatedAt: "Updated At",
      players: "Players",
    },
    filters: {
      gameName_placeholder: "Game Name",
      gameId_placeholder: "Game ID",
    },
  },
};
