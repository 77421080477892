/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Transaction Management
 * API for managing PokerWise transactions.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setSearchParams, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get a paginated list of all transactions. If a tenant ID is provided, retrieve transactions for that tenant only.
         * @summary Retrieve all transactions
         * @param {string} [tenant] The tenant ID to filter transactions by.
         * @param {number} [limit] Maximum number of transactions to return in one page.
         * @param {string} [lastEvaluatedKey] The key for continuing pagination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions: function (tenant_1, limit_1, lastEvaluatedKey_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, limit_1, lastEvaluatedKey_1], args_1, true), void 0, function (tenant, limit, lastEvaluatedKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/transactions";
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (tenant !== undefined) {
                        localVarQueryParameter['tenant'] = tenant;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (lastEvaluatedKey !== undefined) {
                        localVarQueryParameter['lastEvaluatedKey'] = lastEvaluatedKey;
                    }
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get a specific transaction by tenant ID and transaction ID.
         * @summary Retrieve transaction by ID
         * @param {string} tenant The tenant ID associated with the transaction.
         * @param {string} id The unique identifier of the transaction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionById: function (tenant_1, id_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1], args_1, true), void 0, function (tenant, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getTransactionById', 'tenant', tenant);
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getTransactionById', 'id', id);
                    localVarPath = "/transactions/byid/{tenant}/{id}"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get transactions for a specific tenant filtered by amount range.
         * @summary Retrieve transactions by amount
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {number} [minAmount] Minimum transaction amount.
         * @param {number} [maxAmount] Maximum transaction amount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByAmount: function (tenant_1, minAmount_1, maxAmount_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, minAmount_1, maxAmount_1], args_1, true), void 0, function (tenant, minAmount, maxAmount, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getTransactionsByAmount', 'tenant', tenant);
                    localVarPath = "/transactions/byamount/{tenant}"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (minAmount !== undefined) {
                        localVarQueryParameter['minAmount'] = minAmount;
                    }
                    if (maxAmount !== undefined) {
                        localVarQueryParameter['maxAmount'] = maxAmount;
                    }
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get transactions for a specific tenant and game ID.
         * @summary Retrieve transactions by game ID
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} gameid The game ID associated with the transactions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByGameId: function (tenant_1, gameid_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, gameid_1], args_1, true), void 0, function (tenant, gameid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getTransactionsByGameId', 'tenant', tenant);
                    // verify required parameter 'gameid' is not null or undefined
                    assertParamExists('getTransactionsByGameId', 'gameid', gameid);
                    localVarPath = "/transactions/bygameid/{tenant}/{gameid}"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                        .replace("{".concat("gameid", "}"), encodeURIComponent(String(gameid)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get transactions for a specific tenant and table ID.
         * @summary Retrieve transactions by table ID
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} tableid The table ID associated with the transactions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByTableId: function (tenant_1, tableid_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, tableid_1], args_1, true), void 0, function (tenant, tableid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getTransactionsByTableId', 'tenant', tenant);
                    // verify required parameter 'tableid' is not null or undefined
                    assertParamExists('getTransactionsByTableId', 'tableid', tableid);
                    localVarPath = "/transactions/bytableid/{tenant}/{tableid}"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                        .replace("{".concat("tableid", "}"), encodeURIComponent(String(tableid)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get transactions for a specific tenant filtered by a time range.
         * @summary Retrieve transactions by timestamp
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} [startTime] Start timestamp for filtering.
         * @param {string} [endTime] End timestamp for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByTimestamp: function (tenant_1, startTime_1, endTime_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, startTime_1, endTime_1], args_1, true), void 0, function (tenant, startTime, endTime, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getTransactionsByTimestamp', 'tenant', tenant);
                    localVarPath = "/transactions/bytimestamp/{tenant}"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (startTime !== undefined) {
                        localVarQueryParameter['startTime'] = (startTime instanceof Date) ?
                            startTime.toISOString() :
                            startTime;
                    }
                    if (endTime !== undefined) {
                        localVarQueryParameter['endTime'] = (endTime instanceof Date) ?
                            endTime.toISOString() :
                            endTime;
                    }
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get a specific transaction for a user by tenant ID, username, and transaction ID.
         * @summary Retrieve user transaction by ID
         * @param {string} tenant The tenant ID associated with the transaction.
         * @param {string} username The username associated with the transaction.
         * @param {string} id The unique identifier of the transaction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactionById: function (tenant_1, username_1, id_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, username_1, id_1], args_1, true), void 0, function (tenant, username, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getUserTransactionById', 'tenant', tenant);
                    // verify required parameter 'username' is not null or undefined
                    assertParamExists('getUserTransactionById', 'username', username);
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getUserTransactionById', 'id', id);
                    localVarPath = "/transactions/byuser/{tenant}/{username}/byid/{id}"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                        .replace("{".concat("username", "}"), encodeURIComponent(String(username)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get transactions for a user filtered by a time range.
         * @summary Retrieve user transactions by timestamp
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} username The username associated with the transactions.
         * @param {string} [startTime] Start timestamp for filtering.
         * @param {string} [endTime] End timestamp for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactionsByTimestamp: function (tenant_1, username_1, startTime_1, endTime_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, username_1, startTime_1, endTime_1], args_1, true), void 0, function (tenant, username, startTime, endTime, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'tenant' is not null or undefined
                    assertParamExists('getUserTransactionsByTimestamp', 'tenant', tenant);
                    // verify required parameter 'username' is not null or undefined
                    assertParamExists('getUserTransactionsByTimestamp', 'username', username);
                    localVarPath = "/transactions/byuser/{tenant}/{username}/bytimestamp"
                        .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                        .replace("{".concat("username", "}"), encodeURIComponent(String(username)));
                    localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (startTime !== undefined) {
                        localVarQueryParameter['startTime'] = (startTime instanceof Date) ?
                            startTime.toISOString() :
                            startTime;
                    }
                    if (endTime !== undefined) {
                        localVarQueryParameter['endTime'] = (endTime instanceof Date) ?
                            endTime.toISOString() :
                            endTime;
                    }
                    setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         * Get a paginated list of all transactions. If a tenant ID is provided, retrieve transactions for that tenant only.
         * @summary Retrieve all transactions
         * @param {string} [tenant] The tenant ID to filter transactions by.
         * @param {number} [limit] Maximum number of transactions to return in one page.
         * @param {string} [lastEvaluatedKey] The key for continuing pagination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions: function (tenant, limit, lastEvaluatedKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllTransactions(tenant, limit, lastEvaluatedKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getAllTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get a specific transaction by tenant ID and transaction ID.
         * @summary Retrieve transaction by ID
         * @param {string} tenant The tenant ID associated with the transaction.
         * @param {string} id The unique identifier of the transaction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionById: function (tenant, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactionById(tenant, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getTransactionById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get transactions for a specific tenant filtered by amount range.
         * @summary Retrieve transactions by amount
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {number} [minAmount] Minimum transaction amount.
         * @param {number} [maxAmount] Maximum transaction amount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByAmount: function (tenant, minAmount, maxAmount, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactionsByAmount(tenant, minAmount, maxAmount, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getTransactionsByAmount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get transactions for a specific tenant and game ID.
         * @summary Retrieve transactions by game ID
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} gameid The game ID associated with the transactions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByGameId: function (tenant, gameid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactionsByGameId(tenant, gameid, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getTransactionsByGameId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get transactions for a specific tenant and table ID.
         * @summary Retrieve transactions by table ID
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} tableid The table ID associated with the transactions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByTableId: function (tenant, tableid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactionsByTableId(tenant, tableid, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getTransactionsByTableId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get transactions for a specific tenant filtered by a time range.
         * @summary Retrieve transactions by timestamp
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} [startTime] Start timestamp for filtering.
         * @param {string} [endTime] End timestamp for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByTimestamp: function (tenant, startTime, endTime, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactionsByTimestamp(tenant, startTime, endTime, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getTransactionsByTimestamp']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get a specific transaction for a user by tenant ID, username, and transaction ID.
         * @summary Retrieve user transaction by ID
         * @param {string} tenant The tenant ID associated with the transaction.
         * @param {string} username The username associated with the transaction.
         * @param {string} id The unique identifier of the transaction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactionById: function (tenant, username, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserTransactionById(tenant, username, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getUserTransactionById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get transactions for a user filtered by a time range.
         * @summary Retrieve user transactions by timestamp
         * @param {string} tenant The tenant ID associated with the transactions.
         * @param {string} username The username associated with the transactions.
         * @param {string} [startTime] Start timestamp for filtering.
         * @param {string} [endTime] End timestamp for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactionsByTimestamp: function (tenant, username, startTime, endTime, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserTransactionsByTimestamp(tenant, username, startTime, endTime, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getUserTransactionsByTimestamp']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = DefaultApiFp(configuration);
    return {
        /**
         * Get a paginated list of all transactions. If a tenant ID is provided, retrieve transactions for that tenant only.
         * @summary Retrieve all transactions
         * @param {DefaultApiGetAllTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getAllTransactions(requestParameters.tenant, requestParameters.limit, requestParameters.lastEvaluatedKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a specific transaction by tenant ID and transaction ID.
         * @summary Retrieve transaction by ID
         * @param {DefaultApiGetTransactionByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionById: function (requestParameters, options) {
            return localVarFp.getTransactionById(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get transactions for a specific tenant filtered by amount range.
         * @summary Retrieve transactions by amount
         * @param {DefaultApiGetTransactionsByAmountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByAmount: function (requestParameters, options) {
            return localVarFp.getTransactionsByAmount(requestParameters.tenant, requestParameters.minAmount, requestParameters.maxAmount, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get transactions for a specific tenant and game ID.
         * @summary Retrieve transactions by game ID
         * @param {DefaultApiGetTransactionsByGameIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByGameId: function (requestParameters, options) {
            return localVarFp.getTransactionsByGameId(requestParameters.tenant, requestParameters.gameid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get transactions for a specific tenant and table ID.
         * @summary Retrieve transactions by table ID
         * @param {DefaultApiGetTransactionsByTableIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByTableId: function (requestParameters, options) {
            return localVarFp.getTransactionsByTableId(requestParameters.tenant, requestParameters.tableid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get transactions for a specific tenant filtered by a time range.
         * @summary Retrieve transactions by timestamp
         * @param {DefaultApiGetTransactionsByTimestampRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsByTimestamp: function (requestParameters, options) {
            return localVarFp.getTransactionsByTimestamp(requestParameters.tenant, requestParameters.startTime, requestParameters.endTime, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a specific transaction for a user by tenant ID, username, and transaction ID.
         * @summary Retrieve user transaction by ID
         * @param {DefaultApiGetUserTransactionByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactionById: function (requestParameters, options) {
            return localVarFp.getUserTransactionById(requestParameters.tenant, requestParameters.username, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get transactions for a user filtered by a time range.
         * @summary Retrieve user transactions by timestamp
         * @param {DefaultApiGetUserTransactionsByTimestampRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactionsByTimestamp: function (requestParameters, options) {
            return localVarFp.getUserTransactionsByTimestamp(requestParameters.tenant, requestParameters.username, requestParameters.startTime, requestParameters.endTime, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get a paginated list of all transactions. If a tenant ID is provided, retrieve transactions for that tenant only.
     * @summary Retrieve all transactions
     * @param {DefaultApiGetAllTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getAllTransactions = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return DefaultApiFp(this.configuration).getAllTransactions(requestParameters.tenant, requestParameters.limit, requestParameters.lastEvaluatedKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a specific transaction by tenant ID and transaction ID.
     * @summary Retrieve transaction by ID
     * @param {DefaultApiGetTransactionByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getTransactionById = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getTransactionById(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get transactions for a specific tenant filtered by amount range.
     * @summary Retrieve transactions by amount
     * @param {DefaultApiGetTransactionsByAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getTransactionsByAmount = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getTransactionsByAmount(requestParameters.tenant, requestParameters.minAmount, requestParameters.maxAmount, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get transactions for a specific tenant and game ID.
     * @summary Retrieve transactions by game ID
     * @param {DefaultApiGetTransactionsByGameIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getTransactionsByGameId = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getTransactionsByGameId(requestParameters.tenant, requestParameters.gameid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get transactions for a specific tenant and table ID.
     * @summary Retrieve transactions by table ID
     * @param {DefaultApiGetTransactionsByTableIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getTransactionsByTableId = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getTransactionsByTableId(requestParameters.tenant, requestParameters.tableid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get transactions for a specific tenant filtered by a time range.
     * @summary Retrieve transactions by timestamp
     * @param {DefaultApiGetTransactionsByTimestampRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getTransactionsByTimestamp = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getTransactionsByTimestamp(requestParameters.tenant, requestParameters.startTime, requestParameters.endTime, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a specific transaction for a user by tenant ID, username, and transaction ID.
     * @summary Retrieve user transaction by ID
     * @param {DefaultApiGetUserTransactionByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getUserTransactionById = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getUserTransactionById(requestParameters.tenant, requestParameters.username, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get transactions for a user filtered by a time range.
     * @summary Retrieve user transactions by timestamp
     * @param {DefaultApiGetUserTransactionsByTimestampRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getUserTransactionsByTimestamp = function (requestParameters, options) {
        var _this = this;
        return DefaultApiFp(this.configuration).getUserTransactionsByTimestamp(requestParameters.tenant, requestParameters.username, requestParameters.startTime, requestParameters.endTime, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(BaseAPI));
export { DefaultApi };
