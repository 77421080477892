import { jsx as _jsx } from "react/jsx-runtime";
import IdViewer from "@/components/IdViewer";
import { EyeOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
export var columns = function (t, handleOpenDetail) { return [
    { title: t("transactionManagement.columns.gameName"), dataIndex: "id", key: "id", width: 40, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("transactionManagement.columns.username"), dataIndex: "username", key: "username", width: 40, render: function (_, record) { return record.session.user.username; } },
    { title: t("transactionManagement.columns.amount"), dataIndex: "amount", key: "amount", width: 30, render: function (value, record) { return (value ? "".concat(value, " ").concat(record.session.user.currency) : "-"); } },
    { title: t("transactionManagement.columns.type"), dataIndex: "type", key: "type", width: 80, render: function (value) { return _jsx("b", { children: value ? t("transactionManagement.columns.userAction." + value) : "-" }); } },
    {
        title: t("tableManagement.columns.action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 30,
        render: function (_, record) {
            return (_jsx(Button, { buttonType: "primary", buttonSize: "small", icon: _jsx(EyeOutlined, {}), onClick: function () { return handleOpenDetail(record); }, children: t("root.view_button") }));
        },
    },
]; };
