import { LocalStorageKeys } from "@/constants/localStorage";
export var getUser = function () {
    return JSON.parse(localStorage.getItem(LocalStorageKeys.USER_DATA) || "{}");
};
export var setUser = function (user) {
    localStorage.setItem(LocalStorageKeys.USER_DATA, JSON.stringify(user));
};
export var clearUser = function () {
    localStorage.removeItem(LocalStorageKeys.USER_DATA);
    localStorage.removeItem(LocalStorageKeys.TENANT);
};
