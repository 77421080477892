var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CurrencyViewer from "@/components/CurrencyViewer";
import IdViewer from "@/components/IdViewer";
import InfinityTable from "@/components/Infinitytable/InfinityTable";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
import { Col, Drawer, Row } from "antd";
var GameManagementDetail = function (_a) {
    var _b;
    var open = _a.open, recordData = _a.recordData, handleEdit = _a.handleEdit, handleDelete = _a.handleDelete, t = _a.t, onClose = _a.onClose;
    var columns = function (t) { return [
        { title: t("gameManagement.columns.table_id"), dataIndex: "table_id", key: "table_id", width: 100, render: function (id) { return _jsx(IdViewer, { id: id }); } },
        { title: t("gameManagement.columns.type"), dataIndex: "type", key: "type", width: 120, render: function (value) { return value || "-"; } },
        {
            title: t("gameManagement.columns.blinds"),
            dataIndex: "blinds",
            width: 100,
            key: "blinds",
            render: function (blinds, record) { return _jsx(CurrencyViewer, { currency: record.currency, amount1: blinds.small, amount2: blinds.big }); },
        },
        {
            title: t("gameManagement.columns.minBuyIn"),
            dataIndex: "minBuyIn",
            key: "buyInRange",
            width: 80,
            render: function (_, record) { return _jsx(CurrencyViewer, { currency: record.currency, amount1: record.minBuyIn, amount2: record.maxBuyIn }); },
        },
        {
            title: t("gameManagement.columns.players"),
            dataIndex: "totalSeats",
            key: "totalSeats",
            width: 80,
            render: function (_, record) { return (_jsxs("span", { children: [record.currentAllocatedSeats, " / ", record.totalSeats] })); },
        },
        { title: t("gameManagement.columns.id"), dataIndex: "id", key: "id", width: 200, render: function (id) { return _jsx(IdViewer, { id: id }); } },
        { title: t("gameManagement.columns.pot"), dataIndex: "pot", key: "pot", width: 120, render: function (value) { return value || "-"; } },
    ]; };
    var columnsArray = columns(t);
    var tableOptions = {
        containerStyle: {
            height: "auto",
        },
        scroll: { x: 800, y: 300 },
        dataSource: recordData,
        pagination: false,
    };
    console.log("recordData", recordData);
    return (_jsx(Drawer, { width: 900, open: open, onClose: onClose, closeIcon: _jsx(ArrowLeftOutlined, {}), title: ((_b = recordData[0]) === null || _b === void 0 ? void 0 : _b.name) + " " + t("root.details"), children: _jsxs("div", { style: {
                display: "flex",
                flexDirection: "column",
            }, children: [_jsx(InfinityTable, __assign({}, tableOptions, { columns: columnsArray.slice(0, 4) })), _jsx(InfinityTable, __assign({}, tableOptions, { columns: columnsArray.slice(4, columnsArray.length) })), _jsxs(Row, { gutter: 10, children: [_jsx(Col, { sm: 12, children: _jsx(Button, { block: true, buttonType: "dashed", buttonSize: "small", onClick: function () { return handleEdit(recordData[0].id, recordData[0].tenant); }, children: t("root.edit_button") }, "edit") }), _jsx(Col, { sm: 12, children: _jsx(Button, { block: true, buttonType: "primary", buttonSize: "small", onClick: function () { return handleDelete(recordData[0].id); }, children: t("root.delete_button") }, "delete") })] })] }) }));
};
export default GameManagementDetail;
