var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TableControllerApiFactory } from "@/apis/table-management";
import useApi from "@/hooks/useApi";
import { Select } from "@atom/ui-kit";
import { startCase } from "lodash";
import React, { useEffect } from "react";
var TableSelect = function (props) {
    var _a;
    var _b = React.useState(""), searchValue = _b[0], setSearchValue = _b[1];
    var _c = useApi({
        asyncFunction: TableControllerApiFactory().tablesBytypeTenantTypeGet,
    }), callLobbyManagementId = _c.call, tableManagementData = _c.data, tableManagementDataLoading = _c.loading;
    useEffect(function () {
        var _a, _b;
        if ((_b = (_a = props.gameType) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0 > 0) {
            callLobbyManagementId({
                tenant: props === null || props === void 0 ? void 0 : props.tenant,
                type: props.gameType,
                limit: 50,
            });
        }
        return function () {
            setSearchValue("");
        };
    }, [props.gameType]);
    var prepareData = function () {
        var _a;
        if (searchValue.length > 0) {
            return [(_a = tableManagementData === null || tableManagementData === void 0 ? void 0 : tableManagementData.data) === null || _a === void 0 ? void 0 : _a.filter(function (x) { var _a; return (_a = x.name) === null || _a === void 0 ? void 0 : _a.includes(searchValue); })];
        }
        return tableManagementData === null || tableManagementData === void 0 ? void 0 : tableManagementData.data;
    };
    return (_jsx(Select, __assign({ style: { width: "100%" }, loading: tableManagementDataLoading, showSearch: true, onSearch: setSearchValue }, props, { children: (_a = prepareData()) === null || _a === void 0 ? void 0 : _a.map(function (lobby) { return (_jsx(Select.Option, { value: props.selector ? lobby === null || lobby === void 0 ? void 0 : lobby[props.selector] : lobby === null || lobby === void 0 ? void 0 : lobby.id, children: startCase(lobby === null || lobby === void 0 ? void 0 : lobby.name) }, lobby === null || lobby === void 0 ? void 0 : lobby.id)); }) })));
};
export default TableSelect;
