import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TablesPostRequestTypeEnum } from "@/apis/table-management";
import { PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "@atom/ui-kit";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
var TableManagementFilterSpace = function (_a) {
    var onSearch = _a.onSearch, onAddNewData = _a.onAddNewData;
    var form = Form.useForm()[0];
    var t = useTranslation().t;
    var handleSearch = function (values) {
        if (onSearch) {
            onSearch(values);
        }
    };
    var handleFieldChange = debounce(function (changedFields) {
        var _a;
        var changedField = changedFields[0];
        // Değişiklik yapılan alana göre diğer alanları sıfırla
        if (changedField) {
            form.setFieldsValue({
                type: changedField.name[0] === "type" ? form.getFieldValue("type") : undefined,
                tableId: changedField.name[0] === "tableId" ? form.getFieldValue("tableId") : undefined,
                tableName: changedField.name[0] === "tableName" ? form.getFieldValue("tableName") : undefined,
            });
            // Değişiklikleri onSearch işlevine gönder
            onSearch === null || onSearch === void 0 ? void 0 : onSearch((_a = {},
                _a[changedField.name[0]] = changedField.value,
                _a));
        }
    }, 500);
    return (_jsxs(Form, { form: form, layout: "inline", onFinish: handleSearch, initialValues: {
            type: "texasholdem",
        }, onFieldsChange: handleFieldChange, style: {
            width: "100%",
            justifyContent: "space-between",
        }, children: [_jsxs(Row, { gutter: [0, 10], children: [_jsx(Col, { span: 8, children: _jsx(Form.Item, { name: "type", children: _jsx(Select, { onChange: function (value) {
                                    return onSearch === null || onSearch === void 0 ? void 0 : onSearch({
                                        type: value,
                                    });
                                }, style: { width: "100%" }, options: Object.values(TablesPostRequestTypeEnum).map(function (value) { return ({ label: value, value: value }); }), placeholder: t("tableManagement.add.type_placeholder") }) }) }), _jsx(Col, { sm: 8, children: _jsx(Form.Item, { name: "tableId", children: _jsx(Input, { size: "small", addonIcon: _jsx(SearchOutlined, { style: { fontSize: "22px" } }), variant: "borderless", placeholder: "Table ID" }) }) }), _jsx(Col, { sm: 8, children: _jsx(Form.Item, { name: "tableName", children: _jsx(Input, { size: "small", variant: "borderless", addonIcon: _jsx(SearchOutlined, { style: { fontSize: "22px" } }), placeholder: "Table Name" }) }) })] }), _jsxs(Row, { children: [_jsx(Col, { sm: 12, children: _jsx(Form.Item, { children: _jsx(Button, { buttonSize: "small", icon: _jsx(ReloadOutlined, {}), htmlType: "submit", children: t("root.refresh_button") }) }) }), _jsx(Col, { sm: 12, children: _jsx(Button, { buttonSize: "small", icon: _jsx(PlusOutlined, {}), buttonType: "primary", onClick: onAddNewData, children: t("root.add_button") }) })] })] }));
};
export default TableManagementFilterSpace;
