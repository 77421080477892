import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from "@atom/ui-kit";
export var formatCurrency = function (amount, currency) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: currency }).format(amount);
};
export var CurrencyViewer = function (_a) {
    var currency = _a.currency, amount1 = _a.amount1, amount2 = _a.amount2, _b = _a.splitter, splitter = _b === void 0 ? "/" : _b;
    var formattedAmount1 = formatCurrency(amount1, currency);
    var formattedAmount2 = amount2 !== undefined ? formatCurrency(amount2, currency) : "";
    return _jsx(Typography.Text, { children: formattedAmount2 ? "".concat(formattedAmount1).concat(splitter).concat(formattedAmount2) : formattedAmount1 });
};
export default CurrencyViewer;
