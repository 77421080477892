import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DetailPopover from "@/components/DetailPopover";
import IdViewer from "@/components/IdViewer";
import { MoreOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
import { Dropdown, Menu } from "antd";
import dayjs from "dayjs";
export var columns = function (handleEdit, handleDelete, handleOpenDetail, t) { return [
    { title: t("gameManagement.columns.tenant"), dataIndex: "tenant", key: "tenant", width: 120 },
    { title: t("gameManagement.columns.name"), dataIndex: "name", key: "name", width: 200, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("gameManagement.columns.id"), dataIndex: "id", key: "id", width: 200, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("gameManagement.columns.table_id"), dataIndex: "table_id", key: "table_id", width: 200, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("gameManagement.columns.lobby_id"), dataIndex: "lobby_id", key: "lobby_id", width: 200, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("gameManagement.columns.type"), dataIndex: "type", key: "type", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.phase"), dataIndex: "phase", key: "phase", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.currency"), dataIndex: "currency", key: "currency", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.category"), dataIndex: "category", key: "category", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.limitType"), dataIndex: "limitType", key: "limitType", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.version"), dataIndex: "version", key: "version", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.skincode"), dataIndex: "skincode", key: "skincode", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.organization"), dataIndex: "organization", key: "organization", width: 120, render: function (value) { return value || "-"; } },
    // Arrays with Tags
    {
        title: t("gameManagement.columns.players"),
        dataIndex: "players",
        key: "players",
        width: 120,
        render: function (players) { return _jsx(DetailPopover, { data: players }); },
    },
    {
        title: t("gameManagement.columns.deck"),
        dataIndex: "deck",
        key: "deck",
        width: 120,
        render: function (players) { return _jsx(DetailPopover, { data: players }); },
    },
    {
        title: t("gameManagement.columns.seats"),
        dataIndex: "communityCards",
        key: "communityCards",
        width: 120,
        render: function (players) { return _jsx(DetailPopover, { data: players }); },
    },
    // Numbers and Status
    { title: t("gameManagement.columns.lastBetAmount"), dataIndex: "lastBetAmount", key: "lastBetAmount", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.pot"), dataIndex: "pot", key: "pot", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.serviceFeePercent"), dataIndex: "serviceFeePercent", key: "serviceFeePercent", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.totalSeats"), dataIndex: "totalSeats", key: "totalSeats", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.currentPlayerIndex"), dataIndex: "currentPlayerIndex", key: "currentPlayerIndex", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.currentTurn"), dataIndex: "currentTurn", key: "currentTurn", width: 120, render: function (players) { return _jsx(DetailPopover, { data: players }); } },
    { title: t("gameManagement.columns.timeLimitMs"), dataIndex: "timeLimitMs", key: "timeLimitMs", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.minBuyIn"), dataIndex: "minBuyIn", key: "minBuyIn", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.maxBuyIn"), dataIndex: "maxBuyIn", key: "maxBuyIn", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.ante"), dataIndex: "ante", key: "ante", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.timeoutDefaultAction"), dataIndex: "timeoutDefaultAction", key: "timeoutDefaultAction", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.smallBlindIndex"), dataIndex: "smallBlindIndex", key: "smallBlindIndex", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.bigBlindIndex"), dataIndex: "bigBlindIndex", key: "bigBlindIndex", width: 120, render: function (value) { return value || "-"; } },
    { title: t("gameManagement.columns.dealerPosition"), dataIndex: "dealerPosition", key: "dealerPosition", width: 120, render: function (value) { return value || "-"; } },
    // Boolean and Status Columns
    {
        title: t("gameManagement.columns.inProgress"),
        dataIndex: "inProgress",
        key: "inProgress",
        width: 120,
        render: function (inProgress) { return (inProgress ? "Yes" : "No"); },
    },
    {
        title: t("gameManagement.columns.isPasswordProtected"),
        dataIndex: "isPasswordProtected",
        width: 120,
        key: "isPasswordProtected",
        render: function (isProtected) { return (isProtected ? "Yes" : "No"); },
    },
    {
        title: t("gameManagement.columns.isActive"),
        dataIndex: "isActive",
        width: 120,
        key: "isActive",
        render: function (active) { return (active ? "Active" : "Inactive"); },
    },
    // Blinds object
    {
        title: t("gameManagement.columns.blinds"),
        dataIndex: "blinds",
        width: 200,
        key: "blinds",
        render: function (blinds) { return "Small: ".concat(blinds.small, ", Big: ").concat(blinds.big); },
    },
    // Timestamps
    { title: t("gameManagement.columns.createdAt"), dataIndex: "createdAt", key: "createdAt", width: 120, render: function (createdAt) { return dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"); } },
    { title: t("gameManagement.columns.updatedAt"), dataIndex: "updatedAt", key: "updatedAt", width: 120, render: function (createdAt) { return dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"); } },
    // Actions
    {
        title: "Action",
        dataIndex: "action",
        width: 150,
        key: "action",
        fixed: "right",
        render: function (_, record) {
            var menu = (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: function () { return handleEdit(record.id, record.tenant); }, children: t("root.edit_button") }, "edit"), _jsx(Menu.Item, { onClick: function () { return handleDelete(record.id, record.tenant); }, children: t("root.delete_button") }, "delete"), _jsx(Menu.Item, { onClick: function () { return handleOpenDetail(record); }, children: t("root.view_button") }, "delete")] }));
            return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(MoreOutlined, {}), children: t("root.actions_button") }) }));
        },
    },
]; };
